<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.type_spent="{ item }">
      <p v-if="!item.payment_type_api"> {{ item.payment_type_name }} </p>
      <p v-if="item.payment_type_api === 'card'"> บัตรเคดิต/บัตรเดบิต<img src="https://a.moneyspace.net/js/img/payment/Master_VISA_JCB_UNION.png" class="jss94" component="picture or img" alt="payments" style="
    width: 60%;
"></p>
      <p v-if="item.payment_type_api === 'qrnone'"> QR Code  </p>
      <p v-if="item.payment_type_api === 'installment'"> ผ่อนชำระรายเดือน {{ item.bankType }} / {{ item.endTerm }} เดือน</p>
    </template>
    <template v-slot:item.status="{ item }">
      <div  v-if="!item.date_pay">
        <p style="color:#3c763d;" v-if="item.complete_status === 'Y'">(ชำระครบจำนวนแล้ว)</p>
        <p style="color:rgb(222, 66, 62);"
           v-if="item.complete_status === 'N'">(ค้างชำระ)</p>
        <span style="color:rgb(222, 66, 62);;"
              v-if="item.complete_status === 'C'">(ยกเลิก)</span>

      </div>
      <div  v-if="item.date_pay">
        <p style="color:#3c763d;" v-if="item.confirm_pay === 'Y'">(ชำระครบจำนวนแล้ว)</p>
        <p style="color:rgb(222,147,7);"
           v-if="item.confirm_pay === 'W'">(รอการตรวจสอบ)</p>
        <p style="color:rgb(222, 66, 62);;"
           v-if="item.confirm_pay === 'C'">(ยกเลิก)</p>
      </div>
    </template>
    <template v-slot:item.detail="{ item }">
      <button  @click="show(item.mem_package_id)" title="แจ้งชำระ"
               v-if="item.confirm_pay === 'W' && ( (item.payment_type === '1' || item.payment_type === 1)) && !item.payment_type_api"
               class="btn-sm btn btn-warning"><span>แจ้งชำระอีกครั้ง</span>
      </button>

      <button @click="show(item.mem_package_id)" title="รายละเอียด"  v-if="item.confirm_pay === 'Y'"
              class="btn-sm btn btn-primary"><span>รายละเอียด</span>
      </button>
      <a v-if="item.payment_type_api === 'card'" :href="'paymentagainCard/'+item.mem_package_id+'?type=MemberPackageCreditAmount'">
        <button title="แจ้งชำระ"
                class="btn-sm btn btn-warning"><span>ชำระด้วยบัตรเครดิต</span>
        </button>
      </a>
      <a v-if="item.payment_type_api === 'qrnone'" :href="'paymentagainQR/'+item.mem_package_id+'?type=MemberPackageCreditAmount'">
        <button title="แจ้งชำระ"
                class="btn-sm btn btn-warning"><span>แจ้งชำระโดย QR code</span>
        </button>
      </a>
      <a v-if="item.payment_type_api === 'installment'" :href="'paymentagainInstallment/'+item.mem_package_id+'?type=MemberPackageCreditAmount'">
        <button title="แจ้งชำระ"
                class="btn-sm btn btn-warning"><span>แจ้งชำระผ่อนชำระ</span>
        </button>
      </a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance_report from '@/services/report'
import instance_user from '@/services/userManage.js'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false,
      },
      { text: 'รหัสการสั่งซื้อ', value: 'mem_package_no' , sortable: false},
      { text: 'ประเภทแพ็กเกจ', value: 'product_type.product_type_name' , sortable: false },
      { text: 'ประเภทการจ่าย', value: 'payment_type.name'  , sortable: false},
      { text: 'จำนวน', value: 'amount' , sortable: false },
      { text: 'ยอด(บาท)', value: 'total_price' , sortable: false },
      { text: 'สถานะ', value: 'status' , sortable: false },
      { text: 'หมายเหตุ', value: 'confirm_pay_detail' , sortable: false },
      { text: 'แจ้งชำระ/รายละเอียด', value: 'detail'  , sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),


  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getReport()
  },

  methods: {
    show() {
      var id = '';
      // let loader = this.$loading.show({
      //   loader: 'dots',
      //   color: '#27AE60'
      // });
      // this.mem_package.mem_package_id = id;

      instance_user.showMemberPackage(id).then(response => {
        // this.order_cart = response.data.data;
        // this.memberPackage = [];
        // this.memberPackage = response.data.memberPackage;
        // this.package_pay = response.data.package_pay;
        // loader.hide()
      })


      // this.order_cart.push(this.data[index])
      // $('#myModal').modal('show')
    },

    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    getReport() {
      this.$store.state.isLoading = true
      var data = {
        search : '',
        date : '',
        status : '',
      }

      instance_user.getFollowPay(data).then(res => {
        this.reports = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
`
